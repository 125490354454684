<template>
  <AbstractFilter
    :on-filter="filterQuizzes"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="oneQuestion_new"
    filter-panel-class="filter-panel-quiz-one-question"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('quiz.filter.title')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('quiz.filter.id')"
    />
    <Select
      v-model="filter.publicationStatus"
      :options="publicationStatusOptions"
      :label="$t('quiz.filter.status')"
      id="filter_publication_status"
    />
    <Select
      v-model="filter.category"
      :options="categories"
      :label="$t('quiz.filter.category')"
      id="filter_category"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import QuizOneQuestionFilterModel from '@/model/QuizOneQuestionFilter'
import QuizMixin from '@/components/mixins/QuizMixin'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'OneQuestionFilter',
  mixins: [QuizMixin],
  data () {
    return {
      filter: this._.cloneDeep(QuizOneQuestionFilterModel),
      publicationStatusOptions: [
        {
          id: 'active',
          title: this.$t('quiz.filter.active')
        },
        {
          id: 'inactive',
          title: this.$t('quiz.filter.inactive')
        }
      ],
      categories: [],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUIZ_ONE_QUESTION_PERMISSIONS
    }
  },
  components: {
    Input,
    Select,
    AbstractFilter
  },
  methods: {
    filterQuizzes () {
      this.$store.commit('quiz/setPage', 1)
      this.$store.commit('quiz/setOneQuestionFilter', this.filter)
      this.$store.dispatch('quiz/fetchOneQuestionList')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(QuizOneQuestionFilterModel)
      this.$store.commit('quiz/setOneQuestionFilter', this.filter)
      this.$store.dispatch('quiz/fetchOneQuestionList')
    },
    async loadCategories () {
      this.categories = await this.getOneQuestionCategories()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['quiz/oneQuestionFilter']
    this.loadCategories()
  }
}
</script>

<style lang="scss">
.filter-panel-quiz-one-question {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
