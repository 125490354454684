<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="quizzes"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="quiz"
    store-action-reload-name="fetchOneQuestionList"
    table-css-class="quiz-one-question-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="getCategoryTitle(item.category)"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="getDateTime(item.publishedSince)"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="getDateTime(item.publishedUntil)"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
      <StatusColumn
        :header="tableHeaders[6].name"
        :isActive="Boolean(item.enabled)"
        :active-text="$t('quiz.list.active')"
        :inactive-text="$t('quiz.list.inactive')"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import StatusColumn from '@/components/table/columns/StatusColumn'
import QuizMixin from '@/components/mixins/QuizMixin'

export default {
  name: 'QuizOneQuestionTable',
  mixins: [QuizMixin],
  props: {
    quizzes: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    StatusColumn,
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('quiz.list.id') },
        { name: this.$t('quiz.list.title') },
        { name: this.$t('quiz.list.category') },
        { name: this.$t('quiz.list.published_since') },
        { name: this.$t('quiz.list.published_until') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        { name: this.$t('quiz.list.status') },
        { name: this.$t('actions') }
      ],
      categories: []
    }
  },
  async mounted () {
    this.categories = await this.getOneQuestionCategories()
  },
  methods: {
    getCategoryTitle (categoryId) {
      return this.categories.find(category => {
        return categoryId === category.id
      })?.title ?? ''
    },
    getDateTime (dateTime) {
      return dateTime ? this.$options.filters.prettyDateTime(dateTime) : ''
    }
  }
}
</script>
<style lang="scss">
  .quiz-one-question-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) auto rem(100px) rem(100px)  rem(100px)  rem(100px) rem(100px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) auto rem(130px) rem(130px) rem(130px) rem(130px) rem(110px) rem(138px);
      }
    }
  }
</style>
