<template>
  <div class="abstract-data-table__td">
    <span v-if="header" class="abstract-data-table__td-desc">{{ header }}</span>
    <span
      v-if="isActive"
      class="abstract-data-table__td-status abstract-data-table__td-status--active">
      {{ activeText }}
    </span>
    <span
      v-else
      class="abstract-data-table__td-status abstract-data-table__td-status--inactive">
      {{ inactiveText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusColumn',
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    activeText: {
      type: String,
      required: true
    },
    inactiveText: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.abstract-data-table {
  &__td-status {
    padding: rem(8px) rem(13px);
    margin-right: rem(5px);
    border-radius: rem(4px);
    font-size: 75%;
    text-transform: uppercase;
    &--active {
      background-color: #ECF7ED;
      color: #5AB963;
    }
    &--inactive {
      background-color: #82878C;
      color: #FFFFFF;
    }
  }
}
</style>
